import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

// Atoms
import Video from '../atoms/video';
import IconCard from '../atoms/card-icon';

// Logos
// import TaglineReversedLogo from '../logos/logo-tagline-reversed.svg';

// Components
import { HeroWrapper } from './hero';

const HeroHomepage = ({
  title,
  video,
  // benefitTitle,
  benefits,
  tagline,
  ...props
}) => {
  const isSSR = typeof window === 'undefined';
  return (
    <HeroWrapper className={cn('text-center', props.className)}>
      <div
        className={cn(
          'xl:container mobile:px-4 tablet:px-8 lg:px-16 relative',
          props.pt || 'pt-8 lg:pt-10',
          props.pb || 'pb-8 lg:pb-14'
        )}
      >
        {/* <img
          src={TaglineReversedLogo}
          alt=""
          className="mobile+tablet:hidden opacity-10 absolute top-1/4 left-1/2 transform -translate-x-1/2 -translate-y-1/4 w-full max-w-[1440px]"
        /> */}
        <div className="grid grid-cols-12 gap-x-4 lg:gap-x-8">
          <div className="col-span-12 lg:col-start-2 lg:col-span-10">
            <div className="relative">
              {/* <h1 className="text-hero-m lg:text-hero uppercase absolute left-1/2 top-0 transform -translate-x-1/2 -translate-y-[70%] lg:-translate-y-1/2 lg:whitespace-nowrap z-10">
                {title}
              </h1> */}
              {video?.id && (
                <Video
                  title={title}
                  id={video?.id}
                  platform={video?.platform}
                  thumbnail={video?.thumbnail}
                  // autoplay={
                  //   isSSR ? true : !window.localStorage.getItem('autoplayed')
                  // }
                />
              )}
            </div>
            {tagline && title && benefits.length && (
              <div className="bg-white bg-opacity-10 shadow-hero rounded-b-[8px] lg:rounded-b-[17px] px-2 py-6 lg:pt-10 lg:pb-14">
                {title && (
                  <h2 className="text-h4 xs:text-h2.5-m md:text-h2.5 uppercase tracking-widerer mb-2 md:mb-0">
                    {title}
                  </h2>
                )}
                {tagline && (
                  <p className="text-body-md md:text-body-lg">{tagline}</p>
                )}
                {benefits.length > 0 && (
                  <div className="flex items-center justify-center gap-4 lg:gap-12 mt-6 lg:mt-8 px-4">
                    {benefits.map((benefit, key) => (
                      <a href={benefit.title.toLowerCase()}
                        className="flex-initial w-full md:max-w-[16.67%]"
                        key={`icon-card-${key}`}
                      >
                        <IconCard
                          title={benefit.title}
                          description={benefit.description}
                          icon={benefit.icon}
                          variant="white"
                          isCentered
                          size="small"
                          underline
                          iconSize="w-auto h-auto"
                          margin="mb-2"
                        />
                      </a>
                    ))}
                  </div>
                )}
              </div>
            )}
            {/* {tagline && (
              <div className="mt-9 lg:mt-11">
                <p className="u-h4">{tagline}</p>
              </div>
            )} */}
          </div>
        </div>
      </div>
    </HeroWrapper>
  );
};

HeroHomepage.defaultProps = {
  video: {
    platform: '',
    id: '',
    thumbnail: {},
  },
  benefitTitle: '',
  benefits: [
    {
      title: '',
    },
  ],
  tagline: '',
};

HeroHomepage.propTypes = {
  title: PropTypes.string.isRequired,
  video: PropTypes.shape({
    platform: PropTypes.string,
    id: PropTypes.string,
    thumbnail: PropTypes.object,
  }),
  benefitTitle: PropTypes.string,
  benefits: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      icon: PropTypes.string,
    })
  ),
  tagline: PropTypes.string,
};

export default HeroHomepage;
