import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';

// Component
import Layout from '../components/layout';
import Seo from '../components/seo';
import HeroHomepage from '../components/hero-homepage';
// import { PartnerNegative } from '../components/blocks/partner-block';
import BuilderApp from '../components/app-builder';

const HeroWithPartner = styled(HeroHomepage)`
  & > * {
    padding-bottom: ${(props) => props.offsetBottom + 40}px !important;

    @media (min-width: 1024px) {
      padding-bottom: ${(props) => props.offsetBottom + 64}px !important;
    }
  }
`;

const IndexPage = () => {
  // const [partnerOffset, setPartnerOffset] = useState(0);
  // const PartnerRef = useCallback((node) => {
  //   if (node !== null) {
  //     setPartnerOffset(node.clientHeight);
  //   }
  // }, []);

  return (
    <StaticQuery
      query={graphql`
        query IndexPageQuery {
          allContentfulHomePage(filter: { publishable: { eq: "Publish" } }) {
            nodes {
              title
              seoTitle
              seoDescription
              heroTitle
              heroVideoPlatform
              heroVideoId
              heroVideoThumbnail {
                title
                description
                gatsbyImageData(
                  resizingBehavior: FILL
                  cropFocus: FACES
                  formats: [WEBP, JPG]
                  placeholder: BLURRED
                )
              }
              heroBenefitsTitle
              heroBenefits {
                ... on ContentfulBenefit {
                  title
                  icon
                }
              }
              heroTagline
              partnersTitle
              partners {
                ...partnerFragment
              }
              ...homePageFlexColFragment
            }
          }
        }
      `}
      render={(data) => {
        const params = data.allContentfulHomePage.nodes[0];

        return (
          <Layout>
            <Seo
              title={params?.seoTitle || params.title}
              description={params?.seoDescription || ''}
            />
            <HeroWithPartner
              title={params.heroTitle || params.title}
              video={{
                platform: params.heroVideoPlatform,
                id: params.heroVideoId,
                thumbnail: params.heroVideoThumbnail,
              }}
              // benefitTitle={params.heroBenefitsTitle}
              benefits={params.heroBenefits}
              tagline={params.heroTagline}
              // offsetBottom={partnerOffset / 2}
            />
            {/* <PartnerNegative
              ref={PartnerRef}
              title={params.partnersTitle}
              partners={params.partners}
              offsetHeight={partnerOffset / 2}
              className="bg-white"
            /> */}
            {params?.flexibleColumns.length > 0 && (
              <BuilderApp columns={params.flexibleColumns} />
            )}
          </Layout>
        );
      }}
    />
  );
};

export default IndexPage;
